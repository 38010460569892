import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyPage,
  }
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
