<template>
  <!-- ======= Frequently Asked Questions Section ======= -->
  <section id="faq" class="faq section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Frequently Asked Questions</h2>
      </div>

      <div class="row  d-flex align-items-stretch">

        <div class="col-lg-6 faq-item" data-aos="fade-up">
          <h4><a data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">What makes Cloud Speed different from other cloud consulting companies?</a></h4>
          <p class="collapse" id="collapseExample1">
          At Cloud Speed, we are born in the cloud and solely focused on AWS. Our team of AWS-certified professionals possesses deep expertise in AWS technologies, enabling us to deliver tailored solutions that maximize the benefits of the AWS platform. We prioritize customer success, innovation, and reliability, ensuring that our clients receive exceptional service and results.
          </p>
        </div>

        <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="100">
          <h4><a data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">What types of businesses can benefit from your cloud consulting services?</a></h4>
          <p class="collapse" id="collapseExample2">
          Our cloud consulting services are beneficial for businesses of all sizes and industries. Whether you're a startup, small and medium-sized enterprise, or a large corporation, our expertise in AWS technologies can help you harness the power of the cloud to drive growth and innovation. We cater to a diverse range of industries, including finance, healthcare, retail, e-commerce, manufacturing, technology, and more. No matter your industry or business model, we can provide tailored solutions to meet your specific needs and help you achieve your goals in the cloud.
          </p>
        </div>

        <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="200">
          <h4><a data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample3">Can you assist with migrating our existing infrastructure to the cloud?</a></h4>
          <p class="collapse" id="collapseExample3">
          Absolutely. Our team specializes in cloud migration and modernization. We follow a structured approach to assess your current infrastructure, plan the migration strategy, and execute the migration process with minimal disruption. We ensure a seamless transition to the cloud, allowing you to harness the benefits of scalability, security, and cost optimization.
          </p>
        </div>

        <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="300">
          <h4><a data-bs-toggle="collapse" href="#collapseExample4" role="button" aria-expanded="false" aria-controls="collapseExample4">How do you ensure the security of our cloud environment?</a></h4>
          <p class="collapse" id="collapseExample4">
          Security is a top priority for us. We implement robust security measures, including identity and access management, data encryption, network security, and compliance frameworks. Our team follows industry best practices and adheres to stringent security standards to protect your data and systems. We continuously monitor and proactively address any security threats to ensure the integrity and confidentiality of your cloud environment.
          </p>
        </div>

        <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="400">
          <h4><a data-bs-toggle="collapse" href="#collapseExample5" role="button" aria-expanded="false" aria-controls="collapseExample5">Do you provide ongoing support after the implementation?</a></h4>
          <p class="collapse" id="collapseExample5">
          Yes, we offer 24x7 support to ensure the continuous availability and smooth operation of your cloud infrastructure. Our dedicated support team is available to address any technical issues, perform system upgrades, and provide guidance whenever you need it. We prioritize rapid response times and strive to resolve any issues promptly, ensuring your business operations run smoothly.
          </p>
        </div>

        <div class="col-lg-6 faq-item" data-aos="fade-up" data-aos-delay="500">
          <h4><a data-bs-toggle="collapse" href="#collapseExample6" role="button" aria-expanded="false" aria-controls="collapseExample6">How do we get started with Cloud Speed?</a></h4>
          <p class="collapse" id="collapseExample6">
          Getting started with Cloud Speed is easy. Simply reach out to us through our contact form or by contacting our sales team directly. We'll schedule an initial consultation to understand your requirements and discuss how we can assist you. From there, we'll work collaboratively to develop a customized plan and embark on your cloud journey together.
          </p>
        </div>

      </div>

    </div>
  </section><!-- End Frequently Asked Questions Section -->
</template>
<script setup>
</script>